<template>
    <div class="homeOrder" v-loading="loading">
        <div class="title">
            <span>开票信息</span>
            <div class="add" @click="add">添加抬头</div>
        </div>
        <div v-if="addressList && addressList.length == 0"
            style="width: 100%;text-align: center;margin: 50px auto;color: #666;display: flex;justify-content: center;flex-wrap: wrap;">
            <img src="@/assets/wsj.png" style="width: 250px;object-fit: contain;" alt="">
            <div style="width: 100%;margin-top: 10px;">暂无数据</div>
        </div>
        <div class="address" v-for="(i, index) in addressList" :key="index">
            <div class="content">
                <div class="info">
                    <div class="name">{{ i.invoiceTitle }}</div>
                    <div class="addr">{{ i.titleType == 1 ? '个人或事业单位' : i.taxNumber }}</div>
                </div>

                <div class="caoz">
                    <el-button type="text" style="color: #666666;" @click="dele(i.id)">删除</el-button>
                    <el-button type="text" style="color: #333333;" @click="edit(i)">编辑</el-button>
                </div>
            </div>
        </div>

        <!-- 添加对话框 -->
        <el-dialog title="添加开票抬头" :visible.sync="dialogVisible" width="60%" :close-on-click-modal="false"
            :show-close="false">
            <div style="width: 500px;margin: 0 auto;">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
                    <el-form-item label="抬头类型：" prop="receivingInformation"
                        style="display: inline-block;margin-right: 10px;">
                        <el-select v-model="ruleForm.receivingInformation" placeholder="请选择" @change="selectChange">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="发票抬头：" prop="detailedAddress"
                        style="display: inline-block;width: 100%;clear: both">
                        <el-input v-model="ruleForm.detailedAddress"></el-input>
                    </el-form-item>
                    <el-form-item v-if="titleType == 2" label="发票税号：" prop="name"
                        style="display: inline-block;margin-right: 10px;width: 100%;">
                        <el-input v-model="ruleForm.name"></el-input>
                    </el-form-item>
                    <el-form-item v-if="titleType == 2" label="开户银行：" style="display: inline-block;width: 100%;clear: both">
                        <el-input v-model="ruleForm.phone"></el-input>
                    </el-form-item>
                    <el-form-item v-if="titleType == 2" label="银行账号：" style="display: inline-block;width: 100%;clear: both">
                        <el-input v-model="ruleForm.bankAccount"></el-input>
                    </el-form-item>
                    <el-form-item v-if="titleType == 2" label="企业地址：" style="display: inline-block;width: 100%;clear: both">
                        <el-input v-model="ruleForm.enterpriseAddress"></el-input>
                    </el-form-item>
                    <el-form-item v-if="titleType == 2" label="企业电话：" style="display: inline-block;width: 100%;clear: both">
                        <el-input v-model="ruleForm.enterprisePhone"></el-input>
                    </el-form-item>
                </el-form>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="resetForm('ruleForm')">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 编辑对话框 -->
        <el-dialog title="编辑地址" :visible.sync="dialogVisibleEdit" width="50%" :close-on-click-modal="false"
            :show-close="false">
            <!-- <el-form :model="editAddressInfo" :rules="editAddressInfoRules" ref="editAddressInfo" label-position="top"
                label-width="100px" class="demo-ruleForm">
                <el-form-item label="收货人姓名：" prop="name"
                    style="width: calc(50% - 10px); display: inline-block;margin-right: 10px;">
                    <el-input v-model="editAddressInfo.name"></el-input>
                </el-form-item>
                <el-form-item label="手机号码：" prop="telephone"
                    style="width: calc(50% - 10px); display: inline-block;clear: both">
                    <el-input v-model="editAddressInfo.telephone"></el-input>
                </el-form-item>
                <el-form-item label="送货地址：" prop="receivingInformation"
                    style="width: calc(50% - 10px); display: inline-block;margin-right: 10px;">
                    <el-cascader :options="options" :props="{ value: 'label', label: 'label' }"
                        v-model="editAddressInfo.addArr" style="width: 100%;"></el-cascader>
                </el-form-item>
                <el-form-item label="详细地址：" prop="address"
                    style="width: calc(50% - 10px); display: inline-block;clear: both">
                    <el-input v-model="editAddressInfo.address"></el-input>
                </el-form-item>
            </el-form> -->
            <div style="width: 500px;margin: 0 auto;">
                <el-form :model="editAddressInfo" :rules="editAddressInfoRules" ref="editAddressInfo" label-position="top"
                    label-width="120px" class="demo-ruleForm">
                    <el-form-item label="抬头类型：" prop="titleType" style="display: inline-block;margin-right: 10px;">
                        <el-select v-model="editAddressInfo.titleType" :value-key="'label'" placeholder="请选择" @change="selectChange">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="发票抬头：" prop="invoiceTitle" style="display: inline-block;width: 100%;clear: both">
                        <el-input v-model="editAddressInfo.invoiceTitle"></el-input>
                    </el-form-item>
                    <el-form-item v-if="editAddressInfo.titleType == 2" label="发票税号：" prop="taxNumber"
                        style="display: inline-block;margin-right: 10px;width: 100%;">
                        <el-input v-model="editAddressInfo.taxNumber"></el-input>
                    </el-form-item>
                    <el-form-item v-if="editAddressInfo.titleType == 2" label="开户银行："
                        style="display: inline-block;width: 100%;clear: both">
                        <el-input v-model="editAddressInfo.bankName"></el-input>
                    </el-form-item>
                    <el-form-item v-if="editAddressInfo.titleType == 2" label="银行账号："
                        style="display: inline-block;width: 100%;clear: both">
                        <el-input v-model="editAddressInfo.bankAccount"></el-input>
                    </el-form-item>
                    <el-form-item v-if="editAddressInfo.titleType == 2" label="企业地址："
                        style="display: inline-block;width: 100%;clear: both">
                        <el-input v-model="editAddressInfo.companyAddress"></el-input>
                    </el-form-item>
                    <el-form-item v-if="editAddressInfo.titleType == 2" label="企业电话："
                        style="display: inline-block;width: 100%;clear: both">
                        <el-input v-model="editAddressInfo.companyPhone"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="resetForm('editAddressInfo')">取 消</el-button>
                <el-button type="primary" @click="submitForm('editAddressInfo')">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 分页 -->
        <paging style="width: auto !important;" :total="paging.total" @handleCurrentChange="handleCurrentChange"
            :page-size="paging.size"></paging>
    </div>
</template>

<script>
import { postAddInvoice, getInvoiceList, delInvoice, getOrderSelectAddreee, editInvoice } from "@/utils/api/myApi/index"
import paging from "@/components/paging.vue";
export default {
    name: 'myAddress',
    components: {
        paging
    },
    data() {
        return {
            isSelected: true,
            // 添加地址时是否默认地址
            isSelectedAdd: false,
            // 添加对话框
            dialogVisible: false,
            // 编辑对话框
            dialogVisibleEdit: false,
            // 发票类型
            options: [
                {
                    label: '个人或事业单位',
                    value: '1'
                },
                {
                    label: '企业',
                    value: '2'
                }
            ],
            // 添加发票数据
            ruleForm: {
                name: '', // 发票税号
                phone: '', // 开户银行
                receivingInformation: '', // 抬头类型
                detailedAddress: '', // 发票抬头
                bankAccount: '', // 银行账号
                enterpriseAddress: '', // 企业地址
                enterprisePhone: '', // 企业电话
            },
            // 校验
            rules: {
                name: [
                    { required: true, message: '请输发票税号', trigger: 'blur' },
                    { min: 15, max: 20, message: '长度在 15 到 20 个字符', trigger: 'blur' }
                ],
                titleType: [
                    { required: true, message: '请选择抬头类型', trigger: 'change' },
                ],
                detailedAddress: [
                    { required: true, message: '请输入发票抬头', trigger: 'blur' },
                ],
            },
            editAddressInfoRules: {
                taxNumber: [
                    { required: true, message: '请输发票税号', trigger: 'blur' },
                    { min: 15, max: 20, message: '长度在 15 到 20 个字符', trigger: 'blur' }
                ],
                titleType: [
                    { required: true, message: '请选择抬头类型', trigger: 'change' },
                ],
                invoiceTitle: [
                    { required: true, message: '请输入发票抬头', trigger: 'blur' },
                ],
            },
            // 类型 添加 - 编辑
            type: 'add',
            // 分页
            paging: {
                page: 1,
                size: 10,
                total: 0
            },
            // 发票列表
            addressList: [],
            // 加载中
            loading: true,
            // 编辑地址信息
            editAddressInfo: {},
            addreeeId: '',
            // 开票类型
            titleType: 1
        }
    },
    mounted() {
        this.getAddressList()
    },
    methods: {
        // 选择发票类型事件
        selectChange(i) {
            console.log(i)
            this.titleType = i
        },
        // 获取发票列表
        async getAddressList() {
            console.log('获取发票列表')
            const res = await getInvoiceList({
                current: this.paging.page,
                size: this.paging.size
            })
            if (res.statusCode == 8201) {
                this.addressList = res.data.records
                this.paging.total = res.data.total
                this.loading = false
            }
        },
        // 点击编辑
        edit(i) {
            this.editAddressInfo = { ...i }
            this.dialogVisibleEdit = true
            console.log(i)
        },
        // 点击添加
        add() {
            this.dialogVisible = true
            this.type = 'add'
        },
        // 删除发票信息
        dele(ids) {
            this.$confirm('确认删除该信息吗?', '提示', {
                confirmButtonText: '删除',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const res = await delInvoice(ids)
                if (res.statusCode == 8201) {
                    this.$message({
                        type: 'success',
                        message: '删除成功'
                    });
                    this.getAddressList()
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            })
        },
        // 确定按钮
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    // this.loading = true
                    if (formName == 'ruleForm') {
                        let data = {
                            bankAccount: this.ruleForm.bankAccount,
                            bankName: this.ruleForm.phone,
                            companyAddress: this.ruleForm.enterpriseAddress,
                            companyPhone: this.ruleForm.enterprisePhone,
                            invoiceTitle: this.ruleForm.detailedAddress,
                            taxNumber: this.ruleForm.name,
                            titleType: this.titleType,
                        }
                        console.log(data)
                        const res = await postAddInvoice(data)
                        if (res.data) {
                            this.$message.success('添加成功')
                            this.dialogVisible = false
                            this.getAddressList()
                        } else {
                            this.$message.error(res.message)
                        }
                    } else {
                        // 编辑对话框走这里
                        let data = {}
                        if(this.editAddressInfo.titleType == 1) {
                            data.id = this.editAddressInfo.id
                            data.invoiceTitle = this.editAddressInfo.invoiceTitle
                            data.titleType = this.editAddressInfo.titleType
                            data.bankAccount = ''
                            data.bankName = ''
                            data.companyAddress = ''
                            data.companyPhone = ''
                            data.taxNumber = ''
                        } else if(this.editAddressInfo.titleType == 2) {
                            data.bankAccount = this.editAddressInfo.bankAccount 
                            data.bankName = this.editAddressInfo.bankName 
                            data.companyAddress = this.editAddressInfo.companyAddress 
                            data.companyPhone = this.editAddressInfo.companyPhone 
                            data.taxNumber = this.editAddressInfo.taxNumber 
                            data.id = this.editAddressInfo.id 
                            data.invoiceTitle = this.editAddressInfo.invoiceTitle 
                            data.titleType = this.editAddressInfo.titleType
                        }
                        const res = await editInvoice(data)
                        if (res.data) {
                            this.$message.success('添加成功')
                            this.dialogVisibleEdit = false
                            this.getAddressList()
                        } else {
                            this.$message.error(res.message)
                        }
                    }

                } else {
                    console.log('error submit!!', valid);
                    return false;
                }
            });
        },
        // 取消，重置按钮
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.dialogVisible = false
            this.isSelectedAdd = false
            this.dialogVisibleEdit = false
        },
        isSelectedClickEdit() {
            if (this.editAddressInfo.defaults == '1') {
                this.editAddressInfo.defaults = '0'
            } else {
                this.editAddressInfo.defaults = '1'
            }
        },
        // 分页事件
        handleCurrentChange(val) {
            this.loading = true
            this.paging.page = val
            this.getAddressList()
        },
    }
}

</script>

<style lang="less" scoped>
// /deep/ .el-form-item {
//     margin-bottom: 10px;
// }
/deep/ .el-dialog__body {
    padding: 0;
}

.homeOrder {
    width: 1050px;
    height: auto;

    >.title {
        width: 100%;
        height: 40px;
        background-color: #fff;
        padding: 10px 15px;
        box-sizing: border-box;
        font-size: 16px;
        color: #333;
        font-weight: bold;
        display: flex;
        justify-content: space-between;

        >.add {
            width: 80px;
            height: 30px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #FF4242;
            font-size: 12px;
            font-weight: 400;
            color: #FF4242;
            text-align: center;
            line-height: 30px;
            margin-top: -5px;
            cursor: pointer;
        }
    }

    >.address {
        width: 100%;
        height: auto;
        background-color: #fff;
        // padding: 12px;
        box-sizing: border-box;
        margin-top: 20px;

        >.content {
            width: 100%;
            height: 70px;
            display: flex;
            align-items: center;
            // border-bottom: 1px solid #E0E0E0;
            padding: 0 12px;
            box-sizing: border-box;
            justify-content: space-between;

            >.img {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                background-color: #FFCB9A;
                text-align: center;
                line-height: 36px;
                color: #FF531D;
                font-size: 16px;
            }

            >.info {
                font-size: 12px;
                color: #666;
                margin-left: 10px;

                >.name {
                    font-size: 14px;
                    color: #000;
                    display: flex;
                    align-items: center;
                    margin-bottom: 5px;
                }
            }
        }

        >.operation {
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 15px;
            box-sizing: border-box;

        }
    }
}

.mrdz {
    width: 100px;
    display: flex;
    font-size: 12px;
    color: #666;
    cursor: pointer;
    user-select: none;

    >.img {
        width: 16px;
        height: 16px;
        margin-right: 7px;

        >img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

/deep/ .el-button--primary {
    background-color: #FF4242 !important;
    border: 1px solid #FF4242;
}

/deep/ .el-form--label-top .el-form-item__label {
    padding: 0 !important;
}</style>